import olwin from '../assets/anuncis-olwin.jpg';
import cartaBBT2 from '../assets/carta1.JPG';
import Cotard2 from '../assets/Cotard2.jpg';
// import BBT2 from '../assets/imagen-redes-bbt2.png';
import FreeVisualIdentity from '../assets/TOBI.png';
import MonedaPortugal from '../assets/camiseta-portugal.jpg';
import invitacionBoda from '../assets/invitacion.jpg';
import CartelUbaldo from '../assets/shoeg-ubaldo.jpg';
import Sabarra from '../assets/SaBarra.png';
import endulzate from '../assets/endulzate.png';
import KarenEdu from '../assets/invitacionBoda.png';
import lagrafitera from '../assets/lagrafitera.png';
import CCColomenc from '../assets/ccc.png';
import Sweeten from '../assets/Sweeten.png';
import Habito from '../assets/Habito.png'
import TuBanco from '../assets/tuBanco.png'



const pictureList = [
    {
        img: Sweeten,
        tag:'🔥',
        title: 'Sweeten',
        subtitle: '#UX #APP #NewDesign #GoogleCertificationProject #Project' ,
        description: 'Sweeten es una aplicación de ventas por teléfono móvil para una pastelería. El propósito de Sweeten es acercar los dulces a la puerta de cada casa. Para ello se ofrecen dulces hechos por encargo, cómo una amplia gama de ofertas y productos especializados. Para llevar acabo el siguiente proyecto se han realizado investigaciones con usuarios, se han creado user-personas, identificando las diferentes problemáticas y llegando a un proceso de ideación. También se ha realizado una auditoría competitiva. A raíz de esto se realizaron diferentes procesos de creación, hasta alcanzar un prototipo de baja fidelidad, que fue testeado, se adoptaron cambios hasta llegar a uno en alta fidelidad, que volvió a reiterarse con usuarios. De esta manera obtuvimos la primera versión de Sweeten.'
    },
    {
        img: TuBanco,
        tag:'👾',
        title: 'TuBanco',
        subtitle: '#UX #APP #NewDesign #GoogleCertificationProject #Project' ,
        description: 'TuBanco es una parte de una aplicación bancaria, cuyo propósito es reordenar y repensar el proceso de transferencias. Para llevar acabo el siguiente proyecto se han realizado investigaciones con usuarios, se han creado user-personas, identificando las diferentes problemáticas y llegando a un proceso de ideación. También se ha realizado una auditoría competitiva. A raíz de esto se realizaron diferentes procesos de creación, hasta alcanzar un prototipo de baja fidelidad, que fue testeado, se adoptaron cambios hasta llegar a uno en alta fidelidad, que volvió a reiterarse con usuarios.'
    },
    {
        img: Habito,
        tag:'🏆',
        title: 'Hábito',
        subtitle: '#UX #APP #NewDesign #GoogleCertificationProject #Project' ,
        description: 'Hábito es una plataforma con sede en Barcelona cuyo enfoque central es la colaboración ciudadana. La organización necesita una herramienta que ayude a las personas a dar a conocer su opinión y punto de vista. Los principales usuarios objetivo de Habito son los ciudadanos de Barcelona, tanto jóvenes como adultos, que tengan la necesidad de aportar nuevas ideas y puntos de vista sobre como debería ser la ciudad.  En este proyeto se han realizado investigación de usuarios, se creado "user-persona", se ha planteado las diferentes problematicas, y se ha hecho un proceso de ideación. A razí de este primer proceso se han creado prototipos de baja fidelidad, se han testeado, y finalmente se han creado prototipos en alta fidelidad.'
    },
    {
        img: cartaBBT2,
        title: 'Restaurant & Cocteleria BBT2',
        description: 'Identidad para Restaurante, un tono gamberro y divertido.'
    },  
    {
        img: Sabarra,
        title: 'Restaurant Sa Barra',
        description: 'Neon protagonista de la identidad para Sa Barra. '
    }, 
    {
        img: endulzate,
        title: 'Endúlazate',
        description: 'Parte de la identidad para Endulzate, un espacio donde Rita comenzó su hobby y ahora lo ha hecho su profesión. Crea todo tipo de dulces. Con esta identidad pretende abrirse al público de manera profesional y poder realizar encargos, y quien sabe si algún día podrá cumplir su sueño✨✨'
    },
    {
        img: KarenEdu,
        title: 'Boda Karen & Eduardo',
        description: 'Para este evento tan especial se realizó un logotipo inicial, hecho a mano por la pareja. A partir de él se realizaron las invitaciones, una web y elementos como kit-antiresaca o sellos'
    },
    {
        img: lagrafitera,
        title: 'Tipografia "La Grafitera"',
        description: 'Esta tipografía está inspirada en los grafittis callejeros. Pretende innovar el carácter y el tono con el que se lanza el mensaje, entre lo gamberro y lo amable.'
    },
    {
        img: olwin,
        title: 'Olwin',
        description: 'En 2020, Olwin nace como idea para convertirse en una Startup. Una idea innovadora para facilitar la compra-venta de vehículos, donde comprador y vendedor salían ganando...All win'
    },
    {
        img: Cotard2,
        title: 'Tipografia Cotard',
        description: 'COTARD procede del francés “Syndrome de Cotard”, también conocido como delirio de negación o delirio nihilista, (...) El afectado por el síndrome de Cotard cree estar muerto (tanto figurada como literalmente), estar sufriendo la putrefacción de los órganos o simplemente no existir. En algunos casos el paciente se cree incapaz de morir. [...] Esta tipografia es forma, es el cuerpo del texto, en esencia el contenedor de la história. Cotard nace del intento de revivir la tipografía expuesta en la cartelería modernista de Barcelona, de ahi sus proporciones. Por eso no deja atrás el gesto del pincel o el movimiento. Se construye a partir de los trazos de pincel, con un ligero contraste, con una ligera rotación del pincel. Está inspirada en el dúctus de la Americana o tambien llamada Gótica Moderna. Sus trazos dinámicos y fluidos, finalizan con un ligera curva. Con ella se busca una forma algo espontanea y rítmica para poder utilizarse en autoediciones, cómics, etc. Con tal de potenciar su espontaneidad, se han hecho diferentes ligaduras y caracteres alternativos. Está pensada para textos cortos de un tamaño de 14 a 20pt. Aunque tambien dirán que espara textos mayores a 54pt.'
    },
    // {
    //     img: FreeVisualIdentity,
    //     title: 'TOBI (Total Open Brand Identity)',
    //     description: 'TOBI(Total Open Brand Identity) es la conclusión del Trabajo de fin de Grado. Es un recurso gráfico. Es una identidad de libre uso y aplicación, una identidad pensada para poder ser desarrollada por equipos especialmente no especializados en diseño gráfico. Este recurso sienta las bases para crear una identidad visual con recursos gráficos ofimáticos.'
    // },
    // {
    //     img: invitacionBoda,
    //     title: 'Invitación de Boda Alba y Rubén',
    //     description: 'Invitación de Bodas, impreso con amor, en l’Automàtica '
    // },
    // {
    //     img: CartelUbaldo,
    //     title: 'Poster Ubaldo-Shoeg',
    //     description: 'Para Boira Discos. Atmósfera y ruido son los conceptos principales.'
    // },
    {
        img: CCColomenc,
        title: 'Club Ciclista Colomenc',
        description: 'Un club ciclista con más de 92 años de historia, que para otorgar de orgullo a sus socios decide crear un carnet númerado y personalizado.'
    },
    
]

export default pictureList;

