import './Work.css'
import '../../App.css'
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import ImageGallery from 'react-image-gallery';

import * as React from 'react';
import pictureList from '../../Data/data.js';


function Work() {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    
    var img = pictureList[0].img;
    var description = pictureList[0].description;
    var title =  pictureList[0].title;
    var pictureNumber

    function showImageDescroption(picture, index) {
        var pictureConvert = picture.img.toString()
        description = picture.description;
        var title = picture.title;

        document.getElementById('image').src=pictureConvert;
        document.getElementById('description').textContent=description;

        pictureList.forEach(function(value, i){
            if(index == i){
                pictureNumber = value.img
                document.getElementById('buttonSelected'+index).style.color='pink';
                document.getElementById('buttonSelected'+index).style.fontStyle='oblique';
                document.getElementById('buttonSelected'+index).style.textDecoration='underline';
            } else{
                document.getElementById('buttonSelected'+i).style.color='white';
                document.getElementById('buttonSelected'+i).style.fontStyle='';
                document.getElementById('buttonSelected'+i).style.textDecoration='none';
            }
        });
        
        // if(pictureNumber.length > 1){
        //     document.getElementById('image').style.display='none';
        //     document.getElementById('gallery').style.display='inline';
        // } else {
        //     document.getElementById('image').style.display='inline';
        //     document.getElementById('gallery').style.display='none';
        // }

        // img = pictureNumber

        handleClose();
    }

    return (
        <div className='div-content'>
            <header className="App-header" style={{justifyContent: 'flex-start'}}>
            <a className='Subtitle'>El trabajo del</a>
            <a className="Button-header" onClick={() => navigate("/")}> Vecino</a>

            
            </header>

            <body>
                <div className='Container'>
                    {/* galeria */}
                    <div className="carousel">
                        <img className="imageClass" id="image" src={img}></img>
                        {/* <div id="gallery"> */}
                            {/* <ImageGallery  items={img} /> */}
                        {/* </div> */}
                    </div>
                    {/* listado */}
                    
                    <div className='listProducts'>
                        <div className='MenuProduct'> 
                            {pictureList.map((picture, index) => ( 
                            <div style={{display:'flex'}}>
                                <span style={{fontSize:'24px'}}>{picture.tag}</span>
                                <button id={('buttonSelected'+index)} className="textButton"onClick={event => showImageDescroption(picture, index)}>{picture.title}</button>
                                <a style={{fontSize:'14px', color:'pink'}}>{picture.subtitle}</a>
                            </div>
                            ))}
                        </div>
                        <div>  
                            <a className='descriptionClass' id='description' value=''>{description}</a>
                        </div>   
                    </div>
                </div>
                <div>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description">
                         <Box className='Modal'>
                            <div className='OtherTopModalButton'>
                                <Button className="button2" variant="outlined" style={{borderColor: 'pink !important', width: 'fit-content', marginLeft: '20px'}} onClick={handleClose}>Cerrar</Button>
                            </div>
                            {pictureList.map((picture, index) => ( 
                                <div className='ModalProduct'>
                                    <button id={('buttonSelected'+index)} className="textButton"   
                                            onClick={event => showImageDescroption(picture, index)}>{picture.title}</button>
                                </div>
                            ))}
                            <div className='OtherModalButton'>
                                <Button className="button3" variant="outlined" style={{borderColor: 'darkseagreen', width: 'fit-content', marginLeft: '20px'}} onClick={() => navigate("/about")}>Sobre el Vecino </Button>
                                <Button className="button4" variant="outlined" style={{borderColor: 'pink', width: 'fit-content', marginLeft: '20px', marginTop: '10px'}}onClick={() => navigate("/contact")}  > Contactar </Button>
                            </div>
                            </Box>
                    </Modal>
                </div>
            </body>
            <div className='positionWeb'>
                <div className='button-list'>
                <Stack direction="row" spacing={1}>
                    <Button className="button2" variant="outlined" onClick={() => navigate("/")} startIcon={<ArrowBackRoundedIcon/>}> Volver </Button>
                    <Button className="button3" variant="outlined" style={{borderColor: 'darkseagreen'}} onClick={() => navigate("/about")}>Sobre el Vecino </Button>
                    <Button className="button4" variant="outlined" style={{borderColor: 'pink'}}onClick={() => navigate("/contact")}  > Contactar </Button>
                </Stack>
                </div>
            </div>
            <div className='positionMobile'>
                <div className='button-list'>
                <Stack direction="row" spacing={1}>
                    <Button className="button2" variant="outlined" onClick={() => navigate("/")} startIcon={<ArrowBackRoundedIcon/>}> Volver </Button>
                    <Button className='MenuButton' variant="outlined" style={{borderColor: 'darkseagreen'}}  onClick={handleOpen}>Más trabajos</Button>
                </Stack>
                </div>
            </div>
        </div>
    )
}
export default Work;