
import { useNavigate } from 'react-router-dom';
import './App.css';

function App() {
  const navigate = useNavigate();
  return (
    <div className="App" >
      <header className="App-header" style={{justifyContent: 'left'}}>
        <a>Estudio Vecino</a>
      </header>
      <body style={{height: '60vh'}}>
          <a className='clase-a'>👋🏼<br></br> ¡Bienvenido al vecindario!</a>
          <div className="buttonGroup">
            <button onClick={() => navigate("/work")} 
                    className="ButtonCoral">
                    Ver el trabajo
            </button>
            <button onClick={() => navigate("/about")} 
                    className="ButtonDarkseagreen">
                    Sobre el Vecino
            </button>
            <button  onClick={() => navigate("/contact")} 
                    className="ButtonPink">
                    Contactar
            </button>
          </div>
      </body>
    </div>
  );
}

export default App;


