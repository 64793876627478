import { useNavigate } from 'react-router-dom';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import pictureList from '../../Data/data.js';
import { ImageList, ImageListItem } from '@mui/material';
import './About.css'

function About() {
    var img = pictureList[0].img;
    var description = pictureList[0].description;
    var title =  pictureList[0].title;
    const navigate = useNavigate();
    return (      
        <div >
        <header className="App-header" style={{justifyContent: 'flex-start'}}>
          <a className='Subtitle'>Sobre el</a>
          <a className="Button-header" onClick={() => navigate("/")}>Vecino</a>
        </header>
            <body>
            {/* <ImageList sx={{ width: 500, height: 450 }} variant="woven" cols={3} gap={8}>
                {pictureList.map((item) => (
                    <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                    <img src={(item.img)}
                        // {(item.img, 121, item.rows, item.cols)}
                        alt={item.title}
                        loading="lazy"
                    />
                    </ImageListItem>
                ))}
            </ImageList> */}
            <a>Estudio Vecino, es un lugar singular en casa de Edu Vecino, en Santa Coloma de Gramenet (Barcelona).<br></br>
               Un espacio de trabajo personal e ideación de proyectos que giran en torno al diseño gráfico, la tipografía, identidad corporativa, UI/UX Design, ilustración, diseño web y programación.<br></br>
               <br></br>
                (Edu Vecino)<br></br> 
                He trabajado para <a target="_blank" className='Link'  href="https://basora.info/">Basora</a>, con el <a className='Link' target="_blank"  href="https://www.dissenygrafic.org/ca/">Col·legi de Disseny Gràfic de Catalunya</a> y <a className='Link'  target="_blank"  href="https://www.whads.com/es">Whads-Accent</a>. Desde 2017 en <a target="_blank" className='Link'  href="https://es.nttdata.com/">NTTData</a>, durante tres años he trabajado como Front-end Developer y actualmente sigo como UI/UX Designer. 
                <br></br><br></br>
                (2011-2012) Grado de Diseño en Elisava <br></br> 
                (2012-2016) Grado en Artes i Diseño en l'Escola Massana(UAB) <br></br>
                (2017-2018) Postgrado en Diseño tipográfico en Seeway.<br></br> <br></br> </a>
            </body>
            <div className='position'>
                <div className='button-list'>
                <Stack direction="row" spacing={1}>
                    <Button className="button2" variant="outlined" onClick={() => navigate("/")} startIcon={<ArrowBackRoundedIcon/>}> Volver </Button>
                    <Button className="button3" variant="outlined" style={{borderColor: 'darkseagreen'}} onClick={() => navigate("/work")}>Ver el trabajo</Button>
                    <Button className="button4" variant="outlined" style={{borderColor: 'pink'}}onClick={() => navigate("/contact")} > Contactar </Button>
                </Stack>
                </div>
            </div>
        </div>
    )
}
export default About;