import { useNavigate } from 'react-router-dom';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import React, {useRef}  from 'react';
import emailjs from '@emailjs/browser';

import './Contact.css'

function Contact() {
    const form = useRef();
    const navigate = useNavigate();
    const serviceID = 'service_3zx7gnb';
    const templateID = 'template_q3jfmqf';
    const publicKey = 'kAhcSa4ueUj2Po0tc';
   
    const sendEmail = (e) => {
        e.preventDefault();
        const btn = document.getElementById('button');            

        emailjs.sendForm(serviceID, templateID, form.current, publicKey)
          .then((result) => {
                console.log(result.text);
                btn.value = 'Enviado';
                document.getElementById('from_name').style.display='none';
                document.getElementById('reply_to').style.display='none';
                document.getElementById('message').style.display='none';
                document.getElementById('button').style.display='none';
                document.getElementById('mensajeFinal').style.display='block';
          }, (error) => {
              console.log(error.text);
              btn.value = 'Ups! Envialo de nuevo';
          });
      };
    
    return (
        <div>
            <header className="App-header" style={{justifyContent: 'flex-start'}}>
            <a className='subtitle' style={{color:'pink'}}>Contacta con el</a>
            <a className="Button-header" onClick={() => navigate("/")}>Vecino</a>
            </header>

                <body>
                    <form ref={form} onSubmit={sendEmail}>
                        <div className="field">
                            <input style={{borderBottomColor: 'coral'}} required placeholder='Tu nombre' type="text" name="from_name" id="from_name"></input>
                        </div>
                        <div className="field">
                            <input style={{borderBottomColor: 'aquamarine'}}  required placeholder='Tu email' type="text" name="reply_to" id="reply_to"></input>
                        </div>
                        <div className="field">
                            <input style={{borderBottomColor: 'pink'}} required placeholder='Escribe el mensaje' type="text" name="message" id="message"></input>
                        </div>
                        <input type="submit" id="button" value="Enviar correo"></input>
                        
                        <script type="text/javascript" src="https://cdn.jsdelivr.net/npm/@emailjs/browser@3/dist/email.min.js"></script>
                        <script type="text/javascript" src="https://cdn.jsdelivr.net/npm/@emailjs/browser@3/dist/email.min.js"></script>
                        <script type="text/javascript">(function(){emailjs.init(publicKey)})();</script>
                    </form>


                    <div id="mensajeFinal" style={{paddingLeft:'20px', paddingTop:'20px', display: 'none'}}>
                    <div className="text-2xl">Gracias por contactar!!</div>
                    <div className="text-md">¡Te escribriré pronto!😊</div>
                    </div>

                    <div id="mensajeError" style={{paddingLeft:'20px', paddingTop:'20px', display: 'none'}}>
                        <div className="text-2xl">Ups! Ha habido un problema, inténtalo más tarde. Gracias.</div>
                    </div>
                </body>
                <div className='position'>
                    <div className='button-list'>
                    <Stack direction="row" spacing={1}>
                        <Button className="button2" variant="outlined" onClick={() => navigate("/")} startIcon={<ArrowBackRoundedIcon/>}> Volver </Button>
                        <Button className="button3" variant="outlined" style={{borderColor: 'darkseagreen'}} onClick={() => navigate("/about")}>Sobre el Vecino </Button>
                        <Button className="button4" variant="outlined" style={{borderColor: 'pink'}}onClick={() => navigate("/work")}  > Ver trabajo </Button>
                    </Stack>
                    </div>
                </div>
                
        </div>

    )
}
export default Contact;